<template>
  <v-dialog modal v-model="show" width="840">
    <v-card>
      <v-card-title class="text-xs-center justify-center"
        >Копирование привилегий</v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <div class="cardSubheader">
              Откуда
            </div>
            <v-divider class="mb-4"/>
            <span style="color:rgba(0,0,0,.87)">
              {{selectedUser.name}} ({{selectedUser.login}}) id {{selectedUser.id}}
            </span>
          </v-col>
          <v-col cols="6">
            <div class="cardSubheader">
              Куда
            </div>
            <v-divider class="mb-4" />
            <v-autocomplete
              v-for="user in $store.getters.getSelectedUsers" :key="user.id"
              v-model="user.id"
              :items="$store.getters.getUsers"
              item-text="name"
              item-value="id"
              label="Клиент"
              dense
              outlined
              persistent-hint
              @change="updateSelectedUser(user)"
            ></v-autocomplete>
            <v-btn color="primary" @click="addNewSelectedUser">
              <v-icon class="ml-n2 pl-0">mdi-plus</v-icon><span class="ml-1">Клиент</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#F5F5F5"
          style="color: rgba(0, 0, 0, 0.87)"
          outlined
          @click="closeDialog"
        >
          Не копировать
        </v-btn>
        <v-btn
          color="primary"
          @click="saveDialog"
          class="ml-4"
        >
          Применить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    shown: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data: () => ({
    currentFile: null,
  }),
  mounted() {
    this.$store.commit("clearSelectedUsers");
    this.addNewSelectedUser();
  },
  methods: {
    ...mapActions(["CopyAccountPrivs", "GetUsers"]),
    closeDialog() {
      this.$store.commit("clearSelectedUsers");
      this.$emit("close");
    },
    addNewSelectedUser() {
      const user = {
        id: null,
        name: null,
        login: null,
      };
      this.$store.commit("addSelectedUser", { user });
    },
    updateSelectedUser(user) {
      console.log(user);
      //this.$store.commit("addSelectedUser", user);
    },
    async saveDialog() {
      const user_id = this.selectedUser.id;
      const minActive = this.selectedUser.minActive;
      const maxActive = this.selectedUser.maxActive;
      const users = this.$store.getters.getSelectedUsers;
      await this.CopyAccountPrivs({ user_id, minActive, maxActive, users });
      await this.GetUsers();
      this.closeDialog();
    },
  },
  computed: {
    show: {
      get() {
        return this.shown;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    selectedUser: function () {
      this.$store.commit("clearSelectedUsers");
      this.addNewSelectedUser();
    },
  },  
};
</script>
